import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueCookie from 'vue-cookie'   
import '@/icons'
import '@/utils/flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-ui-theme/index.js'
import vform from '../lib/VFormDesigner.umd.min.js'
import '../lib/VFormDesigner.css'
import httpRequest from '@/utils/httpRequest'
import httpRequestAes from '@/utils/httpRequestAes'

import '@/assets/scss/index.scss'
import { JSEncrypt } from 'jsencrypt'
import GLOBAL from "./utils/global";


// 腾讯滑块验证(新建一个js文件)
import TencentCaptcha from '@/assets/TencentCaptcha'
Vue.use(TencentCaptcha);
// 使用
Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(vform)  //同时注册了v-form-designer、v-form-render等组件
Vue.use(VueCookie)
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
Vue.prototype.getPdf = function (title, dom, emptyDomId) {
            // 注册getPdf方法，传入两个参数，此处使用了promise处理导出后的操作
            /*
            title: 导出文件名
            dom: 需要导出dom的id
             */
            return new Promise((resolve, reject) => {
                html2Canvas(dom, {
                    useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
                    allowTaint: true // 允许跨域
                }).then(canvas => {
                    const contentWidth = canvas.width
                    const contentHeight = canvas.height
                    // 根据A4纸的大小，计算出dom相应比例的尺寸
                    const pageHeight = contentWidth / 592.28 * 841.89
                    let leftHeight = contentHeight
                    let position = 0
                    const imgWidth = 595.28
                    // 根据a4比例计算出需要分割的实际dom位置
                    const imgHeight = 592.28 / contentWidth * contentHeight
                    // canvas绘图生成image数据，1.0是质量参数
                    const pageData = canvas.toDataURL('image/jpeg', 1.0)
                    // a4大小
                    const PDF = new JsPDF('', 'pt', 'a4')
                    // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
                    if (leftHeight < pageHeight) {
                        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                    } else {
                        while (leftHeight > 0) {
                            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight
                            position -= 841.89
                            if (leftHeight > 0) {
                                PDF.addPage()
                            }
                        }
                    }
                    // 导出
                    PDF.save(title + '.pdf')
                    // 删除添加的空div

                    const emptyDom = document.getElementById(emptyDomId)
                    emptyDom.remove()
                    resolve(true)
                })
                    .catch(() => {
                        reject(false)
                    })
            })
        }

Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$httpAes = httpRequestAes // ajax请求方法

Vue.prototype.$global = GLOBAL;

if (typeof window !== 'undefined') {
  window.axios = axios
}
const pointDoms = []; // 使用这个指令的所有DOM对象
Vue.directive('points', {
    inserted(el, binding) {
        pointDoms.push(el); // 存储使用这个指令的DOM
        el.addEventListener('click', () => {
            // 禁用所有使用这个指令的DOM结构点击事件
            pointDoms.forEach(pointItem => {
                pointItem.style.pointerEvents = 'none';
            });
            setTimeout(() => {
                // 启动所有使用这个指令的DOM结构点击事件
                pointDoms.forEach(pointItem => {
                    pointItem.style.pointerEvents = 'auto';
                });
            }, binding.value || 2000);
        });
    }
});

// 通过Vue的指令方法，定义指令名称
Vue.directive('Int', {
  // bind绑定 获取当前目标的input（就是在哪个文本框上使用指令）
  bind: function(el) {
      const input = el.getElementsByTagName('input')[0];
      if (input) {
          // 调用input方法，加入正则校验的逻辑
          input.onkeyup = function(e) {
              if (input.value.length === 1) {
                  input.value = input.value.replace(/[^0-9]/g, '');
              } else {
                  input.value = input.value.replace(/[^\d]/g, '');
              }
              // 调用自定义事件
              trigger(input, 'input');
          };
          input.onblur = function(e) {
              if (input.value.length === 1) {
                  input.value = input.value.replace(/[^0-9]/g, '');
              } else {
                  input.value = input.value.replace(/[^\d]/g, '');
              }
              trigger(input, 'input');
          };
      }
  }
});
// 创建自定义事件
const trigger = (el, type) => {
  const e = document.createEvent('HTMLEvents');
  // 初始化默认值
  e.initEvent(type, true, true);
  // 触发自定义事件
  el.dispatchEvent(e);
};
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
