//医联盟管理员
const menuList = [{
		code: "home",
		icon: 'i1',
		selectedIcon: 'i1s',
		id: "home",
		level: 1,
		name: "首页",
		pid: "0",
		resourceIndex: 0,
		resourceUrl: "home",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: []
	},
	{
		code: "case",
		icon: 'i2',
		selectedIcon: 'i2s',
		id: "case",
		level: 1,
		name: "我的病例",
		pid: "2",
		resourceIndex: 1,
		resourceUrl: "/case",
		roleId: "2",
		children: [{
				code: "case",
				icon: '',
				selectedIcon: '',
				id: "case",
				level: 2,
				name: "我的病例",
				pid: "2",
				resourceIndex: 2,
				resourceUrl: "case",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},{
				code: "case-create",
				icon: '',
				selectedIcon: '',
				id: "case-create",
				level: 2,
				name: "添加病例",
				pid: "2",
				resourceIndex: 2,
				resourceUrl: "case-create",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "invite",
				icon: '',
				selectedIcon: '',
				id: "invite",
				level: 2,
				name: "邀请记录",
				pid: "3",
				resourceIndex: 3,
				resourceUrl: "invite",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			
			// {
			// 	code: "analysis",
			// 	icon: '',
			// 	selectedIcon: '',
			// 	id: "analysis",
			// 	level: 2,
			// 	name: "评估分析",
			// 	pid: "3",
			// 	resourceIndex: 3,
			// 	resourceUrl: "analysis",
			// 	roleId: "379c8fded2a04a0e9be436f33be24670",
			// },
			
		]
	},
	{
		code: "caseSearch",
		icon: 'i10',
		selectedIcon: 'i10',
		id: "caseSearch",
		level: 1,
		name: "病例检索",
		pid: "2",
		resourceIndex: 1,
		resourceUrl: "caseSearch",
		roleId: "2",
		children: []
	},
	{
		code: "followUp",
		icon: 'i3',
		selectedIcon: 'i3s',
		id: "followUp",
		level: 1,
		name: "随访管理",
		pid: "0",
		resourceIndex: 1,
		resourceUrl: "",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: [{
				code: "waiting",
				icon: '',
				selectedIcon: '',
				id: "waiting",
				level: 2,
				name: "待随访",
				pid: "3",
				resourceIndex: 2,
				resourceUrl: "waiting",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "followUpList",
				icon: '',
				selectedIcon: '',
				id: "followUpList",
				level: 2,
				name: "随访任务",
				pid: "3",
				resourceIndex: 2,
				resourceUrl: "followUpList",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},]
	},
	{
		code: "queue",
		icon: 'i4',
		selectedIcon: 'i4s',
		id: "queue",
		level: 1,
		name: "队列研究",
		pid: "2",
		resourceIndex: 3,
		resourceUrl: "queue",
		roleId: "2",
		children: []

	},
	{
		code: "zykgl",
		icon: 'i5',
		selectedIcon: 'i5s',
		id: "zykgl",
		level: 1,
		name: "资源中心",
		pid: "3",
		resourceIndex: 4,
		resourceUrl: "",
		roleId: "3",
		children: [
			// {
			//     code: "zykgl/public",
			//     icon: '',
			//     selectedIcon: '',
			//     id: "public",
			//     level: 2,
			//     name: "公告管理",
			//     pid: "3",
			//     resourceIndex: 1,
			//     resourceUrl: "public",
			//     roleId: "379c8fded2a04a0e9be436f33be24670",
			// },
			{
				code: "zykgl/dataCollect",
				icon: '',
				selectedIcon: '',
				id: "dataCollect",
				level: 2,
				name: "问卷库",
				pid: "3",
				resourceIndex: 1,
				resourceUrl: "dataCollect",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "zykgl/plan",
				icon: '',
				selectedIcon: '',
				id: "plan",
				level: 2,
				name: "方案库",
				pid: "3",
				resourceIndex: 2,
				resourceUrl: "plan",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "zykgl/mission",
				icon: '',
				selectedIcon: '',
				id: "mission",
				level: 2,
				name: "宣教库",
				pid: "3",
				resourceIndex: 3,
				resourceUrl: "mission",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
		]
	},
	{

		code: "public",
		icon: 'i6',
		selectedIcon: 'i6s',
		id: "public",
		level: 2,
		name: "科普天地",
		pid: "3",
		resourceIndex: 1,
		resourceUrl: "public",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: [{
			code: "public",
			icon: '',
			selectedIcon: '',
			id: "public",
			level: 2,
			name: "典型案例",
			resourceIndex: 2,
			resourceUrl: "public",
			roleId: "379c8fded2a04a0e9be436f33be24670",
		},
		{
			code: "public2",
			icon: '',
			selectedIcon: '',
			id: "public2",
			level: 2,
			name: "指南共识",
			resourceIndex: 2,
			resourceUrl: "public2",
			roleId: "379c8fded2a04a0e9be436f33be24670",
		},
		{
			code: "public3",
			icon: '',
			selectedIcon: '',
			id: "public3",
			level: 2,
			name: "康复科普",
			resourceIndex: 2,
			resourceUrl: "public3",
			roleId: "379c8fded2a04a0e9be436f33be24670",
		}
	]

	},
	{

		code: "notice",
		icon: 'i7',
		selectedIcon: 'i7s',
		id: "notice",
		level: 2,
		name: "公告消息",
		pid: "3",
		resourceIndex: 1,
		resourceUrl: "notice",
		roleId: "379c8fded2a04a0e9be436f33be24670",
	},
	{
		code: "custom",
		icon: 'i8',
		selectedIcon: 'i8s',
		id: "custom",
		level: 1,
		name: "系统配置",
		pid: "5",
		resourceIndex: 5,
		resourceUrl: "",
		roleId: "5",
		children: [{
				code: "custom/label",
				icon: '',
				selectedIcon: '',
				id: "label",
				level: 2,
				name: "标签管理",
				pid: "5",
				resourceIndex: 1,
				resourceUrl: "label",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "groupList",
				icon: '',
				selectedIcon: '',
				id: "groupList",
				level: 2,
				name: "分组管理",
				pid: "5",
				resourceIndex: 1,
				resourceUrl: "groupList",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			}
		]
	},
	{
		code: "studio",
		icon: 'i9',
		selectedIcon: 'i9s',
		id: "studio",
		level: 1,
		name: "工作室配置",
		pid: "5",
		resourceIndex: 5,
		resourceUrl: "",
		roleId: "5",
		children: [
			{
				code: "basic",
				icon: '',
				selectedIcon: '',
				id: "basic",
				level: 2,
				name: "基本信息配置",
				pid: "5",
				resourceIndex: 1,
				resourceUrl: "basic",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "team",
				icon: '',
				selectedIcon: '',
				id: "team",
				level: 2,
				name: "团队管理",
				pid: "5",
				resourceIndex: 1,
				resourceUrl: "memberAdd",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "quickconfig",
				icon: '',
				selectedIcon: '',
				id: "quickconfig",
				level: 2,
				name: "快捷入口配置",
				pid: "5",
				resourceIndex: 1,
				resourceUrl: "quickconfig",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			{
				code: "sendsform",
				icon: '',
				selectedIcon: '',
				id: "sendsform",
				level: 2,
				name: "系统发送表单",
				pid: "5",
				resourceIndex: 1,
				resourceUrl: "sendsform",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
		]
	},
	
		
	
];
//医联盟管理员
const shortMenuLists = [{
		code: "home",
		icon: 'icon-1',
		selectedIcon: '',
		id: "home",
		level: 1,
		name: "首页",
		pid: "0",
		resourceIndex: 2,
		resourceUrl: "home",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: []
	},
	{
		code: "case",
		icon: 'icon-2',
		selectedIcon: '',
		id: "case",
		level: 1,
		name: "我的病历",
		pid: "2",
		resourceIndex: 2,
		resourceUrl: "/case",
		roleId: "2",
		children: [{
				code: "case",
				icon: '',
				selectedIcon: '',
				id: "case",
				level: 2,
				name: "病历列表",
				pid: "2",
				resourceIndex: 2,
				resourceUrl: "case",
				roleId: "379c8fded2a04a0e9be436f33be24670",
			},
			
		]
	}
	
		
	
];
const menuList2 = [{
		code: "medicine/medicineList",
		icon: 'icon-9',
		selectedIcon: '',
		id: "medicine/medicineList",
		level: 1,
		name: "药品管理",
		pid: "0",
		resourceIndex: 2,
		resourceUrl: "medicine/medicineList",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: []
	},{
		code: "gzsList",
		icon: 'icon-10',
		selectedIcon: '',
		id: "gzsList",
		level: 1,
		name: "工作室管理",
		pid: "0",
		resourceIndex: 2,
		resourceUrl: "gzsList",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: []
	},{
		code: "dict/nrfl",
		icon: 'icon-11',
		selectedIcon: '',
		id: "dict/nrfl",
		level: 1,
		name: "病种管理",
		pid: "0",
		resourceIndex: 2,
		resourceUrl: "dict/nrfl",
		roleId: "379c8fded2a04a0e9be436f33be24670",
		children: []
	},]
export function getMenuList() {
	return menuList;
}
export function shortMenuList() {
	return shortMenuLists;
}

export function getMenuList2() {
	return menuList2;
}