<template>
  <aside class="site-sidebar" :class="'site-sidebar--' + sidebarLayoutSkin">
    <!-- <div class="site-sidebar-name">
      <img style="width: 24px;height: 24px;" src="~@/assets/img/logo.png" alt="">
      <span v-if="!sidebarFold" style="padding-left:8px;">{{title}}</span>
    </div> -->

    <div class="site-sidebar__inner">
      <el-menu
        :default-active="menuActiveName"
        :collapse="sidebarFold"
        :collapseTransition="false"
        :unique-opened="true"
        class="site-sidebar__menu"
      >
        <sub-menu
          style="font-size: 14px;"
          v-for="menu in menuList"
          :key="menu.menuId"
          :menu="menu"
          :dynamicMenuRoutes="dynamicMenuRoutes"
        ></sub-menu>
      </el-menu>
    </div>
  </aside>
</template>

<script>
import SubMenu from "./main-sidebar-sub-menu";
import { isURL } from "@/utils/validate";
export default {
  data() {
    return {
      radio: "",
      dynamicMenuRoutes: [],
      showFlag: true,
      title:'',
    };
  },
  components: {
    SubMenu
  },
  computed: {
    // 联盟名称
    deptName() {
      if (localStorage.dept) {
        let dept = JSON.parse(localStorage.dept);
        return dept.fullName;
      }
    },
    role: {
      get() {
        return this.$store.state.common.role;
      }
    },
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      }
    },
    menuList: {
      get() {
        return this.$store.state.common.menuList;
      },
      set(val) {
        this.$store.commit("common/updateMenuList", val);
      }
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      }
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      }
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      }
    }
  },
  watch: {
    $route: "routeHandle"
  },
  created() {
    this.dynamicMenuRoutes = JSON.parse(
      localStorage.getItem("dynamicMenuRoutes") || "[]"
    );
    this.routeHandle(this.$route);
    if(localStorage.getItem('login')=='login'){
      this.title='精神卫生检查系统'
    }else if(localStorage.getItem('login')=='zdLogin'){
      this.title='东南大学附属中大医院肾脏病诊治中心数据库与病例队列系统'

    }else if(localStorage.getItem('login')=='tnbLogin'){
		this.title = '悟糖实时血糖管理平台'

	  }else if(localStorage.getItem('login')=='kfskLogin'){
		this.title = '康复时空健康管理'

    }
  },
  
  methods: {
    jumpPage(val) {
      console.log(val);
      if (val == 1) {
        window.open("https://fn.qkyxzx.com/user");
      } else {
        window.open("https://fn.qkyxzx.com/");
      }
    },
    // 路由操作
    routeHandle(route) {
      /*let roleId = JSON.parse(localStorage.getItem("userInfo")).roleId
				if(roleId=='1')
				{
					this.showFlag = false
				}*/
      if (route.meta.isTab) {
        // tab选中, 不存在先添加
        var tab = this.mainTabs.filter(item => item.name === route.name)[0];
        if (!tab) {
          if (route.meta.isDynamic) {
            route = this.dynamicMenuRoutes.filter(
              item => item.name === route.name
            )[0];
            if (!route) {
              return console.error("未能找到可用标签页!");
            }
          }
          tab = {
            menuId: route.meta.menuId,
            parentId: route.meta.parentId,
            name: route.name,
            title: route.meta.title,
            type: isURL(route.meta.iframeUrl) ? "iframe" : "module",
            iframeUrl: route.meta.iframeUrl || "",
            params: route.params,
            query: route.query
          };
          this.mainTabs = this.mainTabs.concat(tab);
        }
        // this.menuActiveName = (tab.menuId || tab.parentId) + ''
        // this.menuActiveName = tab.menuId
        // this.menuActiveName = localStorage.menuActiveName
        console.log("tab.menuId ");
        console.log(tab.menuId);
        this.$store.commit("common/updateMenuActiveName", tab.menuId);
        this.mainTabsActiveName = tab.name;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.pdtop {
  padding-top: 20px;
}
.site-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1020;
  // width: 230px;
  overflow: hidden;
  padding-bottom: 50px;
}
.site-sidebar-name {
  // width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  // justify-content: center;
  color: #fff;
  background-color: #3eaaff;
  font-size: 22px;
  padding-left: 16px;
  font-weight: 500;
}
.site-sidebar__menu img {
  width: 20px;
}
 .site-sidebar .el-menu-item .active {
  // background: #444063 !important;
	color: #0476F5  !important;
}

.el-submenu__title i {
  color: #333 !important;
}
</style>