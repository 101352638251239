<template>
  <div class="details">
    <div class="top">
      <div class="left">
        <div class="user">
          <img src="../../assets/img/case/编组 3@2x.png" alt="">
          <span>{{ form.patience_name }}</span>
        </div>
        <div class="sex" v-if="form.patience_sex==1" @change="sexChange()">
          <img src="../../assets/img/case/编组 19@2x.png" alt="">
          <span>男</span>
        </div>
        <div class="sex" v-else-if="form.patience_sex==2" @change="sexChange()">
          <img src="../../assets/img/case/编组 27@2x.png" alt="">
          <span>女</span>
        </div>
        <div class="age">
          <span>{{form.patience_age}}岁</span>
        </div>
        <div v-if="form.main_diagnosis_name" class="case">
          <img src="../../assets/img/case/编组 20@2x.png" alt="">
          <span>{{form.main_diagnosis_name}}</span>
        </div>
      </div>
      <div class="right">
        <span v-if="form.visitInfoTime">最近访视日期：{{form.visitInfoTime}}</span>
      </div>
    </div>
    <div class="main">
      <el-tabs type="card" v-model="activeName">
        <el-tab-pane label="访视记录" name="1">
          <visit-record></visit-record>
        </el-tab-pane>
        <el-tab-pane label="处方记录" name="2">
          <div style="text-align: center;margin-top: 15%;">
            <img style="width: 250px;height: 130px;" src="../../assets/img/zwsj.png" alt="">
            <div>建设中</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="主要体征" name="3">
          <SignView v-if="activeName==3"/>
          <!--        <physical></physical>>  -->
        </el-tab-pane>
        <el-tab-pane label="饮食日记" name="4">
          <div style="text-align: center;margin-top: 15%;">
            <img style="width: 250px;height: 130px;" src="../../assets/img/zwsj.png" alt="">
            <div>建设中</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="指标图标" name="5">
          <div style="text-align: center;margin-top: 15%;">
            <img style="width: 250px;height: 130px;" src="../../assets/img/zwsj.png" alt="">
            <div>建设中</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="沟通交流" name="6">
			<iframe style="width: 100%;height: 600px;" :src="chatUrl"  frameborder="0"></iframe>
			
          <!-- <div style="text-align: center;margin-top: 15%;">
            <img style="width: 250px;height: 130px;" src="../../assets/img/zwsj.png" alt="">
            <div>建设中</div>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="基本信息" name="7">
          <BasicInformation></BasicInformation>
        </el-tab-pane>
        <el-tab-pane label="随访方案" name="8">
          <div style="text-align: center;margin-top: 15%;">
            <img style="width: 250px;height: 130px;" src="../../assets/img/zwsj.png" alt="">
            <div>建设中</div>
          </div>
          <!-- 			{{patiencePrograms}} -->
          <!--  <plan-record v-if="patiencePrograms.length>0" ref="planRecord" :patiencePrograms="patiencePrograms"></plan-record>
          <div  style="text-align: center;margin-top: 15%;"  >暂无数据</div>-->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import SignView from "./SignView.vue";
import { idCardRegExp, idCardFunction,getAge} from "@/utils";
export default {
  components: {
    VisitRecord: () => import("./visit2/index.vue"),
    Physical: () => import("../physical/Index.vue"),
    BasicInformation: () => import("./Info.vue"),
    PlanRecord: () => import("./planRecord.vue"),
    SignView,
  },

  data() {
    return {
      activeName: "1",
	  chatUrl:'',
      patiencePrograms: [],
      form: {
        // main_diagnosis_name:this.$route.params.main_diagnosis_name,
        //   patience_sex:this.$route.params.patience_sex,
        //   patience_name:this.$route.params.patience_name,
        //   age:this.$route.params.patience_age,
        //   visitInfoTime:this.$route.params.visitInfoTime,
      },

      flag: true,
    };
  },

  mounted() {
    this.getInfo();
    this.form = JSON.parse(localStorage.patientInfo);
    this.form.patience_age =getAge( this.form.patience_birth);
	this.chatUrl = "https://ky.docteams.cn/about/#/pc?userId="+ JSON.parse(localStorage.userInfo).id.substring(0,16)+JSON.parse(localStorage.currentTeam).id.substring(0,16) +"&fromAccountID="+JSON.parse(localStorage.patientInfo).patience_id+'&docFlag=true'
  },

  methods: {
    getInfo(type) {
      let _this = this;
      _this.loading = true;
      let params = {
        //   id:this.$route.params.id
        id: JSON.parse(localStorage.patientInfo).id,
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/teamsPatience/info"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.loading = false;

            if (data.data.patiencePrograms.length > 0) {
              _this.patiencePrograms = data.data.patiencePrograms;

              _this.$nextTick(() => {
                _this.$refs.planRecord.init();
              });
            }
          }
        });
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        teamsId: teamsId,
        doctorId: doctorId,
        patienceId: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl("/visitInfo/list"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            if (data.data.length > 0) {
              that.form.visitInfoTime = data.data[0].visitTime;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
	
  .top {
    width: 100%;
    height: 70px;
    background: #f4fbff;
    display: flex;
    .left,
    .right {
      height: 100%;
      width: 50%;
      text-align: right;
    }
    .left {
      display: flex;
      align-items: center;
      padding-left: 23px;
      .user {
        margin-right: 40px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
      .sex {
        margin-right: 47px;
        img {
          width: 19px;
          height: 19px;
        }
      }
      .age {
        margin-right: 47px;
        span {
          display: block;
          width: 50px;
          height: 22px;
          background: #faf6f0;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f1800c;
          line-height: 22px;
          text-align: center;
        }
      }
      .case {
              display: flex;
              align-items: center;
              img {
                width: 18px;
                height: 18px;
                margin-right: 8px;
              }
            }
    }
    .right {
      position: relative;
      padding: 24px 0;
      span {
        display: block;
        position: absolute;
        right: 23px;
        width: 300px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
  }
  .main {
    margin: 26px;
    width: calc(100% - 62px);
    min-height: 500px;
  }
}
</style>