<template>
  <div class="navbar-wrapper">
    <div class="navbar-con">
      <div class="left-con">
        <div class="site-sidebar-name">
          <img
            style="width: 24px; height: 24px"
            src="~@/assets/img/logo.png"
            alt=""
          />
          <span style="padding-left: 8px">精神卫生诊断系统</span>
        </div>
      </div>
      <div class="right-con">
        <div style="display: flex">
          <el-menu
            class="site-navbar__menu site-navbar__menu--right"
            mode="horizontal"
            style="border-bottom: none"
          >
            <el-menu-item
              style="padding-left: 24px"
              class="site-navbar__avatar custom-dropdown"
              index="3"
            >
              <el-dropdown
                :show-timeout="0"
                placement="bottom"
                class="custom-dropdown"
              >
                <span class="el-dropdown-link" style="color: white">
                  <img
                    class="avatar-size"
                    v-if="imgUrl == null || imgUrl == ''"
                    src="~@/assets/img/headerImg.png"
                  />
                  <img v-else :src="imgUrl" />
                  {{ userName }}
                </span>
                <img
                  style="
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                    margin-top: 22px;
                  "
                  src="../assets/img/down.png"
                  alt=""
                />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="gotoAccount()"
                    >账户信息</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="pwdChange()"
                    >修改密码</el-dropdown-item
                  >
        
                  <el-dropdown-item @click.native="logoutHandle()"
                    >退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>

    <div class="nav-content">
      <div class="breadcrumb">
        <el-breadcrumb
          style="height: 40px; line-height: 40px"
          separator="/"
          v-if="$route.meta.remark == 2"
        >
          <el-breadcrumb-item
            v-for="(item, index) in $route.meta.title"
            :to="
              index == 1
                ? {
                    name: 'indicatorInfo',
                    query: { id: quesitonId, activeName: activeName },
                  }
                : { name: 'dataCollect' }
            "
            :key="index"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
        <el-breadcrumb
          v-else
          style="height: 40px; line-height: 40px"
          separator="/"
        >
          <el-breadcrumb-item
            v-for="(item, index) in $route.meta.title"
            :to="{ name: item.linkUrl }"
            :key="index"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <el-dialog title="切换角色" :visible.sync="roleDialogVisible" width="400px">
      <div class="role-change">
        <div
          class="role-item"
          :class="{ active: radio == 1 }"
          @click="changeMenu('1')"
        >
          <div class="img-box">
            <img src="~@/assets/img/role-1.png" />
          </div>
          <div class="role-name">运营人员</div>
        </div>
        <div
          class="role-item"
          style="margin-left: 76px"
          :class="{ active: radio == 2 }"
          @click="changeMenu('2')"
        >
          <div class="img-box">
            <img src="~@/assets/img/role-2.png" />
          </div>
          <div class="role-name">团队成员</div>
        </div>
      </div>
    </el-dialog>
    <!--    <el-dialog
		  title="邀请二维码"
		  :close-on-click-modal="false"
		  :visible.sync="shareDialog"
		  :before-close="handleClose"
		  width="250px"
		>
		  <div class="share-box">
		
		    <div class="code-img">
		      <div class="qrcode" id="qrCode" ref="qrCodeDiv"></div>
		    </div>
		  

		  </div>
    </el-dialog>-->
  </div>
</template>

<script>
import Qs from "qs";
import Bus from "@/utils/bus";
import QRCode from "qrcodejs2";

import { clearLoginInfo } from "@/utils";
import { getMenuList, getMenuList2 } from "../utils/menu";
export default {
  data() {
    return {
      collapseFlag: true,
      deptName: "",
      chatNum: "",
      codeUrl: "",
      codeUrl2: "",
      imageUrl: "", //医院logo
      updatePassowrdVisible: false,
      currentTeam: "", //当前选中的团队
      teamsManages: [],
      roleDialogVisible: false,
      flag: false,
      showTeam: false,
      gzsFlag: false,
      shareDialog: false,
      radio: "2",
      title: "",
    };
  },

  computed: {
    // teamsManages: {
    //   get() {
    //     if (localStorage.teamsManages) {
    //       return JSON.parse(localStorage.teamsManages);
    //     } else {
    //        return this.$store.state.common.teamsManages;
    //     }
    //   },
    //   set(val) {
    //     this.$store.commit("common/updateTeamsManages", val);
    //   }
    // },
    remark() {
      if (this.$route.meta.remark) {
        return this.$route.meta.remark;
      }
    },
    quesitonId() {
      if (this.$route.query.id) {
        return this.$route.query.id;
      }
    },
    activeName() {
      if (this.$route.query.activeName) {
        return this.$route.query.activeName;
      }
    },
    userName() {
      if (localStorage.getItem("userInfo")) {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        return user.userName;
      }
    },
    infoId() {
      if (this.$route.query.id) {
        return this.$route.query.id;
      }
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      },
    },
    userCount: {
      get() {
        return this.$store.state.common.userCount;
      },
      set(val) {
        this.$store.commit("common/updateUserCount", val);
      },
    },
    total: {
      get() {
        return this.$store.state.common.total;
      },
      set(val) {
        this.$store.commit("common/updateTotal", val);
      },
    },
    navbarLayoutType: {
      get() {
        return this.$store.state.common.navbarLayoutType;
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
      set(val) {
        this.$store.commit("common/updateSidebarFold", val);
      },
    },
    imgUrl: {
      get() {
        return this.$store.state.common.imageUrl;
      },
      set(val) {
        this.$store.commit("common/updateImageUrl", val);
      },
    },
    accountInfoFlag: {
      get() {
        return this.$store.state.common.accountInfoFlag;
      },
      set(val) {
        this.$store.commit("common/updateAccountInfoFlag", val);
      },
    },
    pwdChangeFlag: {
      get() {
        return this.$store.state.common.pwdChangeFlag;
      },
      set(val) {
        this.$store.commit("common/updatePwdChangeFlag", val);
      },
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      },
    },
    menuList: {
      get() {
        return this.$store.state.common.menuList;
      },
      set(val) {
        this.$store.commit("common/updateMenuList", val);
      },
    },
    // radio: {
    //   get() {
    //     return this.$store.state.common.roleRadio;
    //   },
    //   set(val) {
    //     this.$store.commit("common/updateRoleRadio", val);
    //   }
    // },
    radioFlag: {
      get() {
        return sessionStorage.radio;
      },
      set(val) {
        this.$store.commit("common/updateRoleRadio", val);
      },
    },
  },
  mounted() {
	
    if (localStorage.getItem("userInfo")) {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user.roleId == "e89325119fa411e89e43525400557296") {
        this.flag = true;
	
		
      } else {

        this.flag = false;
      }
		 
      // this.$store.commit("common/updateImageUrl", uploadImg() + imgUrl.avatar);
    }
    
    if (sessionStorage.radio != undefined) {
      this.radio = sessionStorage.radio;
	  if (this.radio == "1") {

	    this.menuList = getMenuList2();
	    this.roleDialogVisible = false;
	    if (flag == "1") {
	      this.$router.push({
	        name: "medicine/medicineList",
	      });
	    }
	    this.$forceUpdate();
	  } else {

	    this.menuList = getMenuList();
	    this.roleDialogVisible = false;
	    if (flag == "2") {
	      this.$router.push({
	        name: "home",
	      });
	    }
	    this.$forceUpdate();
	  }
    }

    // this.changeMenu();

  },
  methods: {
    gotochat() {
      this.$router.push({
        name: "chat",
      });
    },

  
 


    changeRole() {
      this.roleDialogVisible = true;
    },




    changeSidebarFold() {
      this.collapseFlag = !this.collapseFlag;
      if (this.sidebarFold) {
        this.$store.commit("common/updateSidebarFold", false);
      } else {
        this.$store.commit("common/updateSidebarFold", true);
      }
    },
    goHome() {
      this.$router.push({ name: "home" });
      this.$store.commit("common/updateMenuActiveName", "home");
    },
    goMzjl() {
      this.$router.push({ name: "mzjl" });
      this.$store.commit("common/updateMenuActiveName", "");
    },
    // 获取医院数据
    getHospitalInfo() {
      let that = this;
      let dept = JSON.parse(that.$cookie.get("dept"));
      let params = {
        deptId: dept.id,
      };
      that
        .$http({
          url: that.$http.adornUrl("/deptSetting/info"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.imageUrl = data.data.loginLogo;
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    // 修改密码
    tabsCloseAllHandle() {
      Bus.$emit("getTarget");
    },
    gotoPc() {
      window.open("https://fn.qkyxzx.com/");
    },
    updatePasswordHandle() {
      this.updatePassowrdVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init();
      });
    },

    gotoAccount() {
      this.$router.push({ path: "/account/account-info" });
      this.$store.commit("common/updateMenuActiveName", "account-info");
    },
    pwdChange() {
      this.$router.push({ path: "/account/pwd-change" });
      this.$store.commit("common/updateMenuActiveName", "pwd-change");
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearLoginInfo();
          //localStorage.removeItem('menuList')
          if (sessionStorage.loginType == "zdLogin") {
            this.$router.push({
              name: "zdLogin",
            });
          } else {
            this.$router.push({
              name: "login",
            });
          }
          // this.$router.push({ name: localStorage.getItem('login')});
        })
        .catch(() => {});
    },
    // 修改角色
  },
};
</script>

<style lang="scss" scoped>
.el-menu-item:hover {
  background-color: transparent !important;
}

.navbar-top {
  display: flex;
  flex-direction: row-reverse;
  height: 56px;
  // border-bottom: 1px solid #eee;
  background: linear-gradient(90deg, #3eaaff 0%, #8dbfff 100%);
}

.site-navbar__menu .el-menu-item,
.site-navbar__menu .el-submenu > .el-submenu__title {
  height: 60px;
  line-height: 60px;
}
.site-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 100px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding-left: 230px;
  overflow: hidden;
  &.fold {
    padding-left: 80px;
  }
}
.site-navbar__header {
  position: relative;
  float: left;
  height: 60px;
  padding-right: 80px;
}
.site-navbar__brand {
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.site-navbar__menu .el-menu-item,
.site-navbar__menu .el-submenu > .el-submenu__title {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 48px;
}
.site-navbar__avatar .el-dropdown-link > img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  border-radius: 100%;
  vertical-align: middle;
}
.el-menu-item [class^="el-icon-"] {
  margin-right: 5px;
  width: 35px;
  text-align: center;
  font-size: 35px;
  vertical-align: middle;
}
// 3-12
.right-con {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}
.right-con {
  img {
    width: 23px;
    height: 20px;
  }

  .code-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.avatar-size {
  width: 28px;
  height: 28px;
}
.parent-menu {
  width: 300px;
  height: 60px;
  font-size: 20px;
  display: flex;
  .menu-item {
    padding: 0 40px;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.teams-manage-list {
  color: white;
  display: flex;
  align-items: center;
}
.invite {
  height: 56px;
  cursor: pointer;
  margin-left: 48px;
}
.invite2 {
  height: 56px;
  cursor: pointer;
  margin-left: 24px;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: transparent !important;
}
.navbar-wrapper {
  .navbar-con {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 1999;
    top: 0;
    left: 0;
    background: #0476F5;
    .site-sidebar-name {
      height: 56px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 22px;
      padding-left: 16px;
      font-weight: 500;
    }
  }
}
.nav-content {
  height: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  padding: 56px 0 0 208px;
  .breadcrumb {
    // padding-left: 160px;
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 20px;
  }
}
.role-change {
  display: flex;
  justify-content: center;
  .role-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.active {
      .img-box {
        border: 1px solid #0476F5;
      }
      .role-name {
        color: #0476F5;
      }
    }
  }
  .img-box {
    width: 98px;
    height: 98px;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 55px;
      height: 62px;
    }
  }
  .role-name {
    display: inline-block;
    padding-top: 10px;
  }
}
.share-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  .share-box-text {
    padding-top: 5px;
    color: #666;
  }
}
</style>
