<template>
  <div class="home-page" >

    <div class="tip-con">{{userName}},您好,欢迎进入精神卫生检查系统！</div>

		
  </div>
</template>

<script>
		import Qs from 'qs'
	  import { clearLoginInfo } from '@/utils'
  export default {
    data () {
      return {
        userName: '',
        type: null,
        list: [],//权限列表
        passWord:'',
        passWord2:'',
        dialogVisible:false,
        lastloginTime:'',
      }
    },
    computed: {
      documentClientHeight: {
        get () { return this.$store.state.common.documentClientHeight }
      },
      // siteContentViewHeight () {
      //   var height = this.documentClientHeight - 50 - 30 - 2
      //   return  height
      // }
    },
    mounted () {
      this.userName = JSON.parse(localStorage.getItem('userInfo')).userName
 
      this.lastloginTime = JSON.parse(localStorage.getItem('userInfo')).lastloginTime

    },
    methods: {
			logout(){
				clearLoginInfo()
				//sessionStorage.removeItem('menuList')
				this.$router.push({ name: 'login' })
			},
      gotoAccount(){
        this.$router.push({path: '/account/account-info'})
      },
	  dataFormSubmit() {
	  
	  				let data = {
	  					newPassword: this.passWord2
	  				}
					if(this.passWord==''||this.passWord2==''){
						this.$message.error("密码不能为空")
						return
					}
	  						if(this.passWord!=this.passWord2){
	  							this.$message.error("两次输入的新密码不一致！")
	  							return
	  						}
	  						this.$http({
	  							url: this.$http.adornUrl('user/updatePswd?newPassword='+this.passWord),
	  							method: 'post',
	  							headers: {
	  								'Content-Type': 'application/x-www-form-urlencoded',
	  								 'token':this.$cookie.get('token')
	  							},

	  						}).then(({
	  							data
	  						}) => {
	  							if (data.status) {
	  								this.$message({
	  									message: '修改成功',
	  									type: 'success',
	  									duration: 1500,
	  									onClose: () => {
	  										this.dialogVisible = false
	  										this.$router.push({
	  											name: 'login'
	  										})
	  									}
	  								})
	  							} else {
	  								this.$message.error(data.msg)
	  
	  							}
	  						})
	  				
	  			},
      //进入各自页面
      toSelfPage (path) {
        this.$router.push({path: '/' + path})
      }
    }
  }
</script>

<style lang="scss" scoped="scoped">
  .home-page{

    .tip-con{
      padding: 30px 0;
    }
    .auth-list{
      padding: 80px 40px 0;
      background: #F5F5F5;
      overflow: hidden;
      .list-item{
        width: 16%;
        margin-right: 5.0%;
        height: 180px;
        float: left;
        background:rgba(255,255,255,1);
        box-shadow:-5px 0px 12px 0px rgba(209,207,207,0.43);
        border-radius:6px;
        margin-bottom: 50px;
        cursor: pointer;
        &:nth-child(5n){
          margin: 0;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
          width: 60px;
          height: 60px;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>

