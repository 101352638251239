<template>
  <el-submenu
    v-if="menu.list && menu.list.length >= 1"
    :index="menu.menuId + ''"
    :popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'"
  >
    <template slot="title">
      <svg-icon v-if="menu.icon" :icon-class="menu.icon || ''" class="site-sidebar__menu-icon"></svg-icon>
      <!-- <img
        v-if="menu.icon"
           :src="require('@/assets/icon/'+menu.icon +'.png')"
      />-->

      <span class="sider-line" v-if="menu.icon && parentMenuActiveName == menu.menuId"></span>
      <!-- <img
        v-if="menu.icon && parentMenuActiveName != menu.menuId"
        :src="require('@/assets/icon/'+menu.icon +'.png')"
      >
      <img
        v-else-if="menu.icon && parentMenuActiveName == menu.menuId"
        :src="require('@/assets/icon/'+menu.icon +'s.png')"
      >-->

      <span style="font-size: 14px;padding-left: 12px;">{{ menu.name }}</span>
    </template>
    <sub-menu
      :class="{'active':menuActiveName == item.menuId}"
      style="font-size: 14px"
      v-for="item in menu.list"
      :key="item.menuId"
      :menu="item"
      :dynamicMenuRoutes="dynamicMenuRoutes"
    ></sub-menu>
  </el-submenu>
  <el-menu-item v-else :index="menu.menuId + ''" @click="gotoRouteHandle(menu)">
    <div
      v-if="menuActiveName == menu.menuId"
      style="
        width: 6px;
        height: 100%;
        background: linear-gradient(270deg, #FFFFFF 0%, #EAF6FF 100%);
        position: absolute;
        left: 0;
      "
    ></div>
    <!-- <img
      v-if="menu.icon"
      :src="require('@/assets/icon/'+menu.icon +'.png')"
    />-->

    <span class="sider-line" v-if="menu.icon && menuActiveName == menu.menuId"></span>
    <!-- <img
      v-if="menu.icon && menuActiveName != menu.menuId"
      :src="require('@/assets/icon/'+menu.icon +'.png')"
    >
    <img
      v-else-if="menu.icon && menuActiveName == menu.menuId"
      :src="require('@/assets/icon/'+menu.icon +'s.png')"
    >-->

    <svg-icon v-if="menu.icon" :icon-class="menu.icon || ''" class="site-sidebar__menu-icon"></svg-icon>
    <!-- <div v-if="menu.icon == null" style="width: 40px"></div> -->

    <span :class="menu.icon == null ? 'marginLeft' : ''" style="padding-left: 12px;">{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
import SubMenu from "./main-sidebar-sub-menu";
export default {
  name: "sub-menu",
  components: {
    SubMenu,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    dynamicMenuRoutes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    menuIndex: {
      get() {
        return this.$store.state.common.menuIndex;
      },
      set(val) {
        this.$store.commit("common/updateMenuIndex", val);
      },
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      },
    },
    parentMenuActiveName: {
      get() {
        return this.$store.state.common.parentMenuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateParentMenuActiveName", val);
      },
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      },
    },
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      },
    },
  },
  methods: {
    // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
    gotoRouteHandle(menu) {
		console.log(menu)
      this.$store.commit(
        "common/updateParentMenuActiveName",
        menu.parentMenuId
      );
      this.$store.commit("common/updateMenuActiveName", menu.menuId);
      this.$store.commit("common/updateMenuIndex", menu.pid);
      this.$router.push({ name: menu.resourceUrl });
      if (
        menu.menuId == "case-create" ||
        menu.menuId == "invite" ||
        menu.menuId == "analysis"
      ) {
        // this.$store.commit("common/updateMenuActiveName", "case");
        // const { href } = this.$router.resolve({
        //   name: menu.resourceUrl
        // });
        // window.open(href, "_blank");
      } else {
        this.$router.push({ name: menu.resourceUrl });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdtop {
  padding-top: 20px;
}
.site-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1020;
  // width: 230px;
  overflow: hidden;
  padding-bottom: 50px;
}
.site-sidebar-name {
  // width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #3eaaff;
  font-size: 16px;
}
.site-sidebar__menu img {
  width: 14px;
}
.site-sidebar .el-menu-item .active {
  // background: #444063 !important;
  color: #0476F5 !important;
}

.el-submenu__title i {
  color: #333 !important;
}
span:hover {
  /* color: #fff; */
}
.marginLeft {
  margin-left: 40px;
}
.el-menu-item {
  color: #333 !important;
}
.el-menu-item.is-active {
  background: linear-gradient(270deg, #ffffff 0%, #eaf6ff 100%) !important;
}
.el-submenu .el-menu-item.active {
  background: linear-gradient(270deg, #ffffff 0%, #eaf6ff 100%) !important;
  color: #0476F5 !important;
}
.el-menu-item.is-active:hover {
  /* background-color: #0476F5; */
  color: #333;
}
</style>