var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-wrapper"},[_c('div',{staticClass:"navbar-con"},[_vm._m(0),_c('div',{staticClass:"right-con"},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-menu',{staticClass:"site-navbar__menu site-navbar__menu--right",staticStyle:{"border-bottom":"none"},attrs:{"mode":"horizontal"}},[_c('el-menu-item',{staticClass:"site-navbar__avatar custom-dropdown",staticStyle:{"padding-left":"24px"},attrs:{"index":"3"}},[_c('el-dropdown',{staticClass:"custom-dropdown",attrs:{"show-timeout":0,"placement":"bottom"}},[_c('span',{staticClass:"el-dropdown-link",staticStyle:{"color":"white"}},[(_vm.imgUrl == null || _vm.imgUrl == '')?_c('img',{staticClass:"avatar-size",attrs:{"src":require("@/assets/img/headerImg.png")}}):_c('img',{attrs:{"src":_vm.imgUrl}}),_vm._v(" "+_vm._s(_vm.userName)+" ")]),_c('img',{staticStyle:{"width":"12px","height":"12px","margin-left":"4px","margin-top":"22px"},attrs:{"src":require("../assets/img/down.png"),"alt":""}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.gotoAccount()}}},[_vm._v("账户信息")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.pwdChange()}}},[_vm._v("修改密码")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.logoutHandle()}}},[_vm._v("退出")])],1)],1)],1)],1)],1)])]),_c('div',{staticClass:"nav-content"},[_c('div',{staticClass:"breadcrumb"},[(_vm.$route.meta.remark == 2)?_c('el-breadcrumb',{staticStyle:{"height":"40px","line-height":"40px"},attrs:{"separator":"/"}},_vm._l((_vm.$route.meta.title),function(item,index){return _c('el-breadcrumb-item',{key:index,attrs:{"to":index == 1
                ? {
                    name: 'indicatorInfo',
                    query: { id: _vm.quesitonId, activeName: _vm.activeName },
                  }
                : { name: 'dataCollect' }}},[_vm._v(_vm._s(item.name))])}),1):_c('el-breadcrumb',{staticStyle:{"height":"40px","line-height":"40px"},attrs:{"separator":"/"}},_vm._l((_vm.$route.meta.title),function(item,index){return _c('el-breadcrumb-item',{key:index,attrs:{"to":{ name: item.linkUrl }}},[_vm._v(_vm._s(item.name))])}),1)],1)]),_c('el-dialog',{attrs:{"title":"切换角色","visible":_vm.roleDialogVisible,"width":"400px"},on:{"update:visible":function($event){_vm.roleDialogVisible=$event}}},[_c('div',{staticClass:"role-change"},[_c('div',{staticClass:"role-item",class:{ active: _vm.radio == 1 },on:{"click":function($event){return _vm.changeMenu('1')}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/img/role-1.png")}})]),_c('div',{staticClass:"role-name"},[_vm._v("运营人员")])]),_c('div',{staticClass:"role-item",class:{ active: _vm.radio == 2 },staticStyle:{"margin-left":"76px"},on:{"click":function($event){return _vm.changeMenu('2')}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/img/role-2.png")}})]),_c('div',{staticClass:"role-name"},[_vm._v("团队成员")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-con"},[_c('div',{staticClass:"site-sidebar-name"},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("@/assets/img/logo.png"),"alt":""}}),_c('span',{staticStyle:{"padding-left":"8px"}},[_vm._v("精神卫生诊断系统")])])])
}]

export { render, staticRenderFns }